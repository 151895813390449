import Twig from 'twig/twig.min';
import PopupBaseTemplate from '../../../../templates/frontParts/ui/popup/base.twig';
import CopyToClipboard from '../../../../templates/frontParts/ui/popup/copyToClipboard.twig';
import { disableNoScrollBody, murmur2, noScrollBody } from '../utils/main';
import { hideAllModal } from '../utils/modals';

class ModalController {
	static timeout;

	constructor() {
		this.base = Twig.twig({
			data: PopupBaseTemplate,
		});

		this.copyToClipboard = Twig.twig({
			data: CopyToClipboard,
		});
	}

	async renderCopyToClipboard(data) {
		const content = await this.copyToClipboard.render(data ? {
			...data,
		} : {});

		const id = `modal-${murmur2(content)}`;
		const html = await this.base.render({
			class: '-copy-complete copyToClipboardModal',
			modalName: 'copyToClipboardModal',
			id,
			content,
		});

		return { id, html };
	}

	async showCopyToClipboard(data) {
		const { id, html } = await this.renderCopyToClipboard(data);

		document.body.insertAdjacentHTML('beforeend', html);

		const popup = document.querySelector(`#${id}`);

		this.registerEvents(popup);
		this.showPopup(popup, 3000);
	}

	showPromocodeCopyToClipboard() {
		this.showCopyToClipboard({
			title: 'Промокод скопирован',
		});
	}

	showPopup(popup, time = false) {
		hideAllModal();

		popup.classList.add('-show');

		requestAnimationFrame(() => noScrollBody());

		if (time) {
			ModalController.timeout = setTimeout(() => {
				this.hidePopup(popup);
			}, time);
		}
	}

	hidePopup(popup) {
		this.clearTimeout();

		popup.classList.remove('-show');
		popup.remove();

		disableNoScrollBody();
	}

	registerEvents(popup) {
		popup.querySelector('.cross')?.addEventListener('click', () => this.hidePopup(popup));
		popup.querySelector('.crossBtn')?.addEventListener('click', () => this.hidePopup(popup));
		popup.querySelector('.bg')?.addEventListener('click', () => this.hidePopup(popup));
	}

	clearTimeout() {
		ModalController.timeout && clearTimeout(ModalController.timeout);
	}
}

const modalController = new ModalController();

export default modalController;

$(function () {
    const $root = $('.store-categories')
    if ($root.length) {
        $($root).find('.store-categories__show').on('click', function () {
            $($root).find('.store-categories__inner').addClass('store-categories__inner--active')
            $(this).hide()
        })
    }

    if (location.hash){
        const toEl = $root.find(location.hash)
        if(toEl.length && getComputedStyle(toEl[0]).display == 'none'){
            ($root).find('.store-categories__show').trigger('click')
        }
    }
});
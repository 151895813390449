import copy from 'copy-to-clipboard';
import { hideModal, showModal } from '../utils/modals';
import modalController from './modal-controller';

document.querySelectorAll('.copyToClipboard').forEach(item => {
	let timeout;
	const completeModal = document.querySelector('.copyToClipboardModal');

	if (completeModal) {
		completeModal.addEventListener('show', () => {
			if (timeout) clearTimeout(timeout);
		});
	}

	item.addEventListener('click', () => {
		const targetId = item.getAttribute('data-copy-target');
		const copyContent = item.getAttribute('data-copy');
		const target = document.querySelector('#' + targetId);

		if (target) {
			copy(target.textContent);

			if (completeModal) {
				showModal($(completeModal));
				timeout = setTimeout(() => hideModal($(completeModal)), 3000);
			}
		}

		if (copyContent) {
			copy(copyContent);

			if (completeModal) {
				showModal($(completeModal));
				timeout = setTimeout(() => hideModal($(completeModal)), 1000);
			}
		}
	});
});

document.querySelectorAll('.copyPromocodeToClipboard').forEach(item => {
	item.addEventListener('click', event => {
		const targetId = item.getAttribute('data-copy-target');
		const copyContent = item.getAttribute('data-copy');
		const target = document.querySelector('#' + targetId);

		const allTextBlocks = document.querySelectorAll('.copy-block--active');
		const allDataBlocks = document.querySelectorAll('.text--active');
		const allStubDataBlocks = document.querySelectorAll('.stub-text--inactive');
		const allBlocks = document.querySelectorAll('.copyPromocodeToClipboard');

		allTextBlocks.forEach(textBlock => {
			textBlock.classList.remove('copy-block--active');
		});
		allStubDataBlocks.forEach(allStubDataBlock => {
			allStubDataBlock.classList.remove('stub-text--inactive');
		});
		allBlocks.forEach(allBlock => {
			allBlock.classList.add('code-overlay');
		});
		allDataBlocks.forEach(allDataBlock => {
			allDataBlock.classList.remove('text--active');
		});

		if (target) {
			copy(target.textContent);
		}

		// обработка клика на блок
		if (copyContent) {
			copy(copyContent);

			const textBlock = item.nextElementSibling;
			const dataBlock = item.querySelector('.text');
			const stubDataBlock = item.querySelector('.stub-text');

			textBlock.classList.add('copy-block--active');
			item.classList.remove('code-overlay');

			dataBlock.classList.add('text--active');
			stubDataBlock.classList.add('stub-text--inactive');

			setTimeout(function () {
				textBlock.classList.remove('copy-block--active');
			}, 10000);
		}
	});
});

// проверить есть ли такой элемент
let copyButton = document.querySelector('.copyPromocodeToClipboard');

if (copyButton) {
	document.addEventListener('click', function (event) {
		let testTarget = event.target;
		const allTextBlocks = document.querySelectorAll('.copy-block--active');
		const allDataBlocks = document.querySelectorAll('.text--active');
		const allStubDataBlocks = document.querySelectorAll('.stub-text--inactive');
		const allBlocks = document.querySelectorAll('.copyPromocodeToClipboard');

		if (
			testTarget.classList.contains('button') ||
			testTarget.classList.contains('filters__btn-category') ||
			testTarget.classList.contains('input') ||
			testTarget.classList.contains('btn') ||
			testTarget.classList.contains('purple-btn-bgs')
		) {
			allTextBlocks.forEach(textBlock => {
				textBlock.classList.remove('copy-block--active');
			});
			allStubDataBlocks.forEach(allStubDataBlock => {
				allStubDataBlock.classList.remove('stub-text--inactive');
			});
			allBlocks.forEach(allBlock => {
				allBlock.classList.add('code-overlay');
			});
			allDataBlocks.forEach(allDataBlock => {
				allDataBlock.classList.remove('text--active');
			});
		}
	});
}

// Открытие промокодов в личном кабинете

const copyBlocks = document.querySelectorAll('.copyOrderToClipboard');
copyBlocks.forEach(copyBlock => {
	copyBlock.addEventListener('click', () => {
		const targetId = copyBlock.getAttribute('data-copy-target');
		const copyContent = copyBlock.getAttribute('data-copy');
		const target = document.querySelector('#' + targetId);

		if (target) {
			copy(target.textContent);
		}

		if (copyContent) {
			copy(copyContent);
		}

		const parentElement = copyBlock.parentElement;
		const nextElement = parentElement.nextElementSibling;
		nextElement.classList.add('copy-block--active');
		setTimeout(() => {
			nextElement.classList.remove('copy-block--active');
		}, 10000);

		// Закрываем все остальные блоки
		copyBlocks.forEach(otherCopyBlock => {
			if (otherCopyBlock !== copyBlock) {
				const otherParentElement = otherCopyBlock.parentElement;
				const otherNextElement = otherParentElement.nextElementSibling;
				otherNextElement.classList.remove('copy-block--active');
			}
		});
	});
});


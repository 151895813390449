import Lottie from 'lottie-web';

export class CardMore {
	constructor(root, insetTo, fetch, afterInsert) {
		this.root = root;
		this.insertAdjacent = insetTo;
		this.fetch = fetch;
		this.afterInsert = afterInsert;

		this.offersDB = new Map();

		this.registerEvents();
		this.preloadIcon();
	}

	collectionHas(a, b) {
		for (let i = 0, len = a.length; i < len; i++) {
			if (a[i] == b) return true;
		}
		return false;
	}

	findParentBySelector(elm, selector) {
		let all = document.querySelectorAll(selector);
		let cur = elm.parentNode;
		while (cur && !this.collectionHas(all, cur)) {
			cur = cur.parentNode;
		}
		return cur;
	}

	registerEvents() {
		this.root.addEventListener('click', e => {
			if (e.target.closest('.showMoreCardBtn')) {
				const bankid = e.target.getAttribute('data-bankid');
				const offerid = e.target.getAttribute('data-offerid');
				const key = `${bankid}-${offerid}`;
				const insertEl = this.findParentBySelector(e.target, this.insertAdjacent);

				if (!this.offersDB.has(key)) {
					this.showPreloadIcon(e.target);

					e.target.classList.add('showMoreCardBtnLoading');

					this.fetch(bankid, offerid).then(resp => {
						if (insertEl && resp.offers) {
							this.offersDB.set(key, resp.offers);

							e.target.classList.remove('showMoreCardBtnLoading');
							e.target.classList.add('showMoreCardBtnActive');

							this.destroyAnimation();
							this.showOffers(insertEl, resp.offers);
						}
					});
				} else {
					e.target.classList.add('showMoreCardBtnActive');
					this.showOffers(insertEl, this.offersDB.get(key));
				}
			}
		});
	}

	showOffers(insertEl, offers) {
		const container = document.createElement('div');
		container.classList.add('showMoreCardList');
		container.innerHTML = offers;

		const btnCloseUp = document.createElement('span');
		btnCloseUp.classList.add('showMoreCardClose');
		btnCloseUp.innerText = 'Скрыть';
		btnCloseUp.addEventListener('click', e => {
			this.hideOffers(e);
		});

		container.prepend(btnCloseUp);

		const btnCloseDown = document.createElement('span');
		btnCloseDown.classList.add('showMoreCardClose');
		btnCloseDown.innerText = 'Скрыть';
		btnCloseDown.addEventListener('click', e => {
			this.hideOffers(e);
		});

		container.append(btnCloseDown);

		insertEl.append(container);

		if (this.afterInsert) {
			this.afterInsert();
		}
	}

	hideOffers(e) {
		window.scrollTo(0, this.findParentBySelector(e.target, '.company-cart__wrapper').offsetTop)

		const card = this.findParentBySelector(e.target, this.insertAdjacent);
		if (!card) return false;

		const list = card.querySelector('.showMoreCardList');
		if (!list) return false;

		list.remove();

		const btnLoading = card.querySelector('.showMoreCardBtn');
		if (!btnLoading) return false;

		btnLoading.classList.remove('showMoreCardBtnActive');

		
	}

	async preloadIcon() {
		const response = await fetch('/img/preload-loop.json');
		this.iconData = await response.json();
	}

	showPreloadIcon(preloaderContainer) {
		if (this.iconData && preloaderContainer) {
			this.preloader = Lottie.loadAnimation({
				container: preloaderContainer,
				animationData: this.iconData,
				renderer: 'svg',
				loop: true,
				autoplay: true,
			});
		}
	}

	destroyAnimation() {
		if (this.preloader) {
			const svg = this.preloader.wrapper.querySelector('svg');
			if (svg) {
				svg.remove();
			}
		}
	}
}

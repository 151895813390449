$(function () {
    if ($('.main-banner__text_geo').length) {
        const elem = $('.main-banner__text_geo');

        const lenText = elem.text().length
        const bufferText = elem.text()

        if (lenText > 255) {
            elem.text(bufferText.slice(0, 255) + '...')
            const btn = $('<span class="main-banner__text_geo-more">еще</span>')
            btn.on('click', function () {
                $(this).remove()
                elem.text(bufferText)
            })
            elem.append(btn)
        }

        $('.main-banner__text_geo').addClass('showText')
    }
})
import {hideModal} from "../utils/modals";
import {showSomethingWrongModal, showSuccessModal} from "../utils/modals";
import {enabledForm} from "../utils/form";


$('.modalReviewThank').hide();

$('.showModalReview').on('click', function (e) {
    e.preventDefault();
    $('.modalReview').css('display', 'flex');
    $('.overlay-modal').css('display', 'block');
    $('body').css('overflow', 'hidden');
})

$('#partner-modal-form').on('submit', function (e) {
    if ($('.rating__stars--modal i').hasClass('active')) {
        $('.rating__stars--modal').removeClass('error');
    } else {
        $('.rating__stars--modal').addClass('error');
        return false;
    }
    let checkedInput = 0;
    $('#input2 [type="checkbox"]').each(function () {
        if ($(this).prop('checked')) {
            checkedInput += 1;
        }
    })
    if (checkedInput == 0) {
        $('#input2').addClass('error');
        return false;
    } else {
        $('#input2').removeClass('error');
    }
    const emailVal = $('input[name="email"]').val();
    const pattern = /^[a-z0-9_-]+@[a-z0-9-]+\.([a-z]{1,6}\.)?[a-z]{2,6}$/i;
    if (!pattern.test(emailVal) || emailVal == '') {
        $('.partner-modal__form-item--email').find('#valid').html('Введите корректный адрес');
        return false;
    } else {
        $('.partner-modal__form-item--email').find('#valid').remove();
    }

    const $form = $(this);
    let theme = [];
    let rating = $('#partner-modal-form').find('input[name="rating"]').val();
    let userName = $('#partner-modal-form').find('input[name="userName"]').val();
    let email = $('#partner-modal-form').find('input[name="email"]').val();
    let title = $('#partner-modal-form').find('textarea[name="title"]').val();
    let feedback = $('#partner-modal-form').find('textarea[name="feedback"]').val();
    let isAgree = $('#partner-modal-form').find('input[name="isAgree"]').prop('checked');
    let newsletter = $('#partner-modal-form').find('input[name="newsletter"]').prop('checked');
    let intersectKey = $('#partner-modal-form').find('input[type="hidden"]').attr('name');
    let intersectValue = $('#partner-modal-form').find('input[type="hidden"]').val();

    $('#partner-modal-form').find('#input2 [type="checkbox"]:checked').each(function () {
        theme.push($(this).attr('value'))
    })
    const data = {
        rating: rating,
        userName: userName,
        email: email,
        theme: theme,
        title: title,
        feedback: feedback,
        isAgree: isAgree,
        newsletter: newsletter,
    }

    data[intersectKey] = intersectValue;

    $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: data,
        success: function (response) {
            if (response.status && response.status === 200) {
                showSuccessModal('Письмо, с ссылкой для подтверждения, отправлено вам на почту');
                $('.modalReviewThank').show();
                $('form').trigger('reset');
            } else if (response.status && response.status === 'error' || (rating === "" || userName === "" || email === "" || theme == null || title === "" || feedback === "" || isAgree === "" || newsletter === "")) {
                showSomethingWrongModal(response.data.error_msg);
            } else if (response.status && response.status === 429) {
                showSomethingWrongModal(response.data.error_msg);
            } else {
                $('.modalReviewThank').show();
                $('form').trigger('reset');
            }
        },
    })
    e.preventDefault();
})

$('body').on('click', ".closeWindow", function () {
    $('body').css('overflow', '');
    $('.window').hide();
    $('.overlay-modal').hide();
    $('.overlay-mobile').css('display', 'none');
});

$(document).on('mouseup', function (e) {
    let s = $('.partner-modal');
    if (!s.is(e.target) && s.has(e.target).length === 0) {
        s.css('display', 'none');
        $('.overlay-modal').css('display', 'none');
        $('body').css('overflow', '');
    }
});

$('.btnShow').on('click', function (e) {
    e.preventDefault();
    const data = {
        currentShow: $('.partners__page-reviews-item-wrap').length - 1,
        selectEntity: Number($('.selectEntity').val()),
        sortBy: window.location.search.replace('?', '') === 'feedbackOrderBy=rating' ? 'rating' : 'createdAt',
    };
    const link = $(this).data('url');
    $.ajax({
        method: 'POST',
        url: link,
        data: JSON.stringify(data),
        success: function (response) {
            if (response.isLast === true) {
                $('.reviews-about-partner__inner').append(response.offers);
                $('.btnShow').hide();
            } else if (response.offers) {
                $('.reviews-about-partner__inner').append(response.offers);
                $('#totalFeedbacksLeft').html(response.totalFeedbacksLeft);
            }
        },
    })
})

const ta = document.querySelector('.textareaCount') // textarea
const counter = document.querySelector('.data-count') // счётчик
const nt = document.querySelector('.textarea-title');

if (ta && counter && nt) {
    ta.addEventListener('input', onInput)
    nt.addEventListener('input', onInput)

    function onInput(evt) {
        const length = evt.target.value.length
        counter.textContent = length
    }

    ta.oninput = function () {
        this.value = this.value.substr(0, 1500);
    }

    nt.oninput = function () {
        this.value = this.value.substr(0, 255);
    }
}
$(function () {
    if ($('.counter-day').length) {
        const time = $('.counter-day__time').attr('data-init-time')

        if (window.moment) {
            const h = parseFloat(getComputedStyle($('.counter-day')[0]).height) + parseFloat(getComputedStyle($('.counter-day')[0]).marginTop)
            $('body').attr('style', `--counter-h:${h}px`)
            $('.counter-day').addClass('counter-day--active')

            let interval = undefined

            const run = function () {
                const fiveSecondsFromNow = moment(time, 'YYYY-MM-DD H:mm:ss');
                const now = moment();

                const daysRemaining = fiveSecondsFromNow.diff(now, 'days');

                const hours = fiveSecondsFromNow.diff(now, 'hours');
                const hoursRemaining = hours - (24 * daysRemaining);

                const minutes = fiveSecondsFromNow.diff(now, 'minutes');
                const minutesRemaining = minutes - (hours * 60);

                if (fiveSecondsFromNow < now) {
                    $('.counter-day').hide()
                    clearInterval(interval)
                } else {
                    $('.counter-day__time [data-d]').text(`${daysRemaining} д`)
                    $('.counter-day__time [data-h]').text(`${hoursRemaining} ч`)
                    $('.counter-day__time [data-m]').text(`${minutesRemaining} м`)
                }
            }

            interval = setInterval(function () {
                run();
            }, 500)

            run();


        }
    }
})
import { FormValidator } from "../utils/form";
import { showSomethingWrongModal, showSuccessModal } from "../utils/modals";

$(function () {
    if ($('#we-are-in-social-networks-form').length) {
        const $weAreInSocialNetworksForm = $('#we-are-in-social-networks-form').eq(0);
        const formValidator = new FormValidator($weAreInSocialNetworksForm[0]);

        formValidator.addField('[name="sub_email"]', [FormValidator.rules.required, FormValidator.rules.email, FormValidator.rules.maxLength(200, 'Максимум 200 символов')]);

        formValidator.onSuccess((form) => {
            const jsonData = JSON.stringify({
                'email': $weAreInSocialNetworksForm.find('[name="sub_email"]').val(),
            });

            $weAreInSocialNetworksForm.find('.button').attr('disabled', true)

            $.ajax({
                url: '/subscribeToNewsletterWidgetAjax',
                method: 'POST',
                data: jsonData,
                success: function (response) {
                    if (response.status === 'success') {
                        showSuccessModal(response.message)
                        $weAreInSocialNetworksForm.find('[name="sub_email"]').val('')
                    } else {
                        showSomethingWrongModal(response.message)
                    }
                },
                complete: function () {
                    $weAreInSocialNetworksForm.find('.button').removeAttr('disabled', true)
                },
            });
        });
    }
})
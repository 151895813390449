class AccordionButton {
	constructor(root) {
		this.$root = root;
		this.$accordion = document.querySelector(`[data-accordion-content="${this.$root.getAttribute('data-accordion-handler')}"]`);

		this.registerEvents();
	}

	registerEvents() {
		this.$root.addEventListener('click', () => {
			if (this.$root.classList.contains('-active')) {
				this.$root.classList.remove('-active');

				if (this.$accordion) $(this.$accordion).slideUp();
			} else {
				this.$root.classList.add('-active');

				if (this.$accordion) $(this.$accordion).slideDown();
			}
		});
	}
}

document.querySelectorAll('[data-accordion-handler]').forEach(root => {
	new AccordionButton(root);
});

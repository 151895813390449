import { disabledForm, enabledForm, FormValidator } from '../../utils/form';
import { showSomethingWrongModal, showSuccessModal } from '../../utils/modals';

const $passwordRecoveryForm = document.querySelector('form#password-recovery');

if ($passwordRecoveryForm) {
	const passwordRecoveryFormValidator = new FormValidator($passwordRecoveryForm);

	passwordRecoveryFormValidator.addField('[name="email"]', [FormValidator.rules.required, FormValidator.rules.email]);
	passwordRecoveryFormValidator.onSuccess((form) => {
		disabledForm(form);

		const email = $(form).find('[name="email"]').val();
		const actionUrl = form.getAttribute('action');

		$.ajax({
			url: actionUrl,
			method: 'POST',
			data: {
				email,
			},
			success: ({
						  status,
						  message,
					  }) => status === 200 ? showSuccessModal(message) : showSomethingWrongModal(message),
			complete: () => enabledForm(form),
		});
	});

}

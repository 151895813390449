import Lottie from 'lottie-web';

$(function () {
    const $root = $('.sitemap-footer')

    const createLink = (el) => {
        return $(`<a href="${el.url}" class="ajax-load-item">${el.name.charAt(0).toUpperCase() + el.name.slice(1)}</a>`)
    }

    const createBox = (links, title) => {
        let box = $(`<div class="sitemap-footer__item ajax-load-col">
            <span class='sitemap-footer__itemTitle'>${title}</span>
            <div class="sitemap-footer__itemLinks"></div>
        </div>`)

        links.map((el) => {
            box.find('.sitemap-footer__itemLinks').append(createLink(el))
        })

        return box
    }

    if ($root.length) {
        let iconData = ''

        fetch('/img/preload-loop.json').then((resp) => {
            resp.json().then(img => {
                iconData = img
            })
        })

        $root.find('.sitemap-footer__load button').on('click', function () {
            const postData = { existIdList: {} }
            const clickButton = $(this);
            const urlAction = clickButton.attr('data-action-url')

            if (clickButton.hasClass('load')) {
                $root.find('.sitemap-footer__item').each(function () {
                    const name = $(this).find('.sitemap-footer__itemTitle').text()
                    const ids = []

                    $(this).find('.sitemap-footer__itemLinks a').each(function () {
                        ids.push(parseInt($(this).attr('id')))
                    })

                    postData.existIdList[name] = ids;
                })

                Lottie.loadAnimation({
                    container: $(this).parent()[0],
                    animationData: iconData,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                });

                $(this).removeClass('load');

                $.ajax({
                    url: urlAction,
                    type: "POST",
                    data: JSON.stringify(postData),
                    contentType: "application/json",
                    dataType: "json",
                    success: function (res) {

                        if (res.extendedListExistingBlocks) {
                            $root.find('.sitemap-footer__item').each(function () {
                                const name = $(this).find('.sitemap-footer__itemTitle').text()
                                if (res.extendedListExistingBlocks[name]) {
                                    res.extendedListExistingBlocks[name].map((el) => {
                                        $(this).find('.sitemap-footer__itemLinks').append(createLink(el))
                                    })

                                }
                            })
                        }

                        if (res.newBlocksExtendedList) {
                            for (let boxTitle of Object.keys(res.newBlocksExtendedList)) {
                                $root.find('.sitemap-footer__list').append(
                                    createBox(res.newBlocksExtendedList[boxTitle], boxTitle)
                                )
                            }
                        }

                        if (res.moreBlock) {
                            const links = []
                            for (let itemName of Object.keys(res.moreBlock)) {
                                links.push({ url: res.moreBlock[itemName], name: itemName })
                            }

                            links.reverse()

                            $root.find('.sitemap-footer__list').append(
                                createBox(links, 'Еще')
                            )
                        }

                        $root.find('.sitemap-footer__load svg').remove();
                        clickButton.find('span').text('Скрыть');
                        clickButton.find('img').css('transform', 'rotate(180deg)');
                    }
                })
            } else {
                $('.ajax-load-item, .ajax-load-col').remove();
                clickButton.addClass('load');
                clickButton.find('span').text('Показать ещё');
                clickButton.find('img').css('transform', 'rotate(0deg)');
                $([document.documentElement, document.body]).animate({
                    scrollTop: $('.sitemap-footer__title').offset().top
                }, {
                    duration: 100,
                    easing: 'easeInSine'
                });
            }
        })
    }
})
import { hideModal, showModal } from '../utils/modals';

$('body').on('click', '[data-modal-handler]', function (e) {
	e.preventDefault();

	const $this = $(this);
	const [modalHandler, tabIndex] = $this.data('modal-handler').split('#');
	const $modal = $('[data-modal-js="' + modalHandler + '"]');

	document.querySelectorAll('[data-modal-js]').forEach(item => item.classList.remove('-show'));

	if (tabIndex !== undefined) {
		const $tabs = $modal.find('.tabs');

		if ($tabs.attr('data-tabs-active-js')) {
			$tabs.trigger('indexChange', tabIndex);
		} else {
			$modal.find('.tabs__header button')
				.removeClass('active')
				.eq(tabIndex)
				.addClass('active');
			$modal.find('.tabs__item').hide().eq(tabIndex).show(0);
		}
	}

	if ($modal.is(':visible')) {
		hideModal($modal);
	} else {

		if ($this.attr('data-modal-redirect')) {
			$modal.attr('data-modal-redirect', $this.attr('data-modal-redirect'))
		}

		showModal($modal);
	}
});


// $('[data-modal-handler]').each(function(index, item) {
// 	const $this = $(item);
// 	const [modalHandler, tabIndex] = $this.data('modal-handler').split('#');
// 	const $modal = $('[data-modal-js="' + modalHandler + '"]');

// 	$this.on('click', function(e) {
// 		e.preventDefault();

// 		document.querySelectorAll('[data-modal-js]').forEach(item => item.classList.remove('-show'));

// 		if (tabIndex !== undefined) {
// 			const $tabs = $modal.find('.tabs');

// 			if ($tabs.attr('data-tabs-active-js')) {
// 				$tabs.trigger('indexChange', tabIndex);
// 			} else {
// 				$modal.find('.tabs__header button')
// 					.removeClass('active')
// 					.eq(tabIndex)
// 					.addClass('active');
// 				$modal.find('.tabs__item').hide().eq(tabIndex).show();
// 			}
// 		}

// 		if ($modal.is(':visible')) {
// 			hideModal($modal);
// 		} else {
// 			showModal($modal);
// 		}
// 	});
// });

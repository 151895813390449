export function toggleCashbackDisplay() {
    const openDetailsButton = document.getElementById('open-cashback-details');
    const cashbackTrigger = document.querySelector('.webviewCashbackTrigger');

    if (openDetailsButton) {
        openDetailsButton.addEventListener('click', function () {
            cashbackTrigger.style.display = 'grid';
            openDetailsButton.parentNode.parentNode.style.display = 'none';
        });
    }
}
window.modalActivateCashBackShow = async function (title = '') {
    const html = `
        <div class="modal-activete-cashback">
            <div class="modal-activete-cashback__inner">
                <img class="modal-activete-cashback__logo" src="/img/vector/logo.svg" alt="Логотип" />

                <div class="modal-activete-cashback__body">
                    <span class="modal-activete-cashback__title">
                        Активируем кэшбэк<br> в ${title}, для этого:
                    </span>

                    <div class="modal-activete-cashback__steps">
                        <div class="modal-activete-cashback__step">
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="56" rx="10" fill="#E3FFB6"/>
                                <path d="M17 25H39M19.7 37H36.3C37.7912 37 39 35.7912 39 34.3V21.7C39 20.2088 37.7912 19 36.3 19H19.7C18.2088 19 17 20.2088 17 21.7V34.3C17 35.7912 18.2088 37 19.7 37Z" stroke="black" stroke-width="1.5"/>
                                <path d="M21 22C21 22.5523 20.5523 23 20 23C19.4477 23 19 22.5523 19 22C19 21.4477 19.4477 21 20 21C20.5523 21 21 21.4477 21 22Z" fill="black"/>
                                <path d="M24 22C24 22.5523 23.5523 23 23 23C22.4477 23 22 22.5523 22 22C22 21.4477 22.4477 21 23 21C23.5523 21 24 21.4477 24 22Z" fill="black"/>
                                <path d="M27 22C27 22.5523 26.5523 23 26 23C25.4477 23 25 22.5523 25 22C25 21.4477 25.4477 21 26 21C26.5523 21 27 21.4477 27 22Z" fill="black"/>
                            </svg>

                            <span>
                                Оставайтесь на сайте магазина, не переходите на мобильное приложение
                            </span>
                        </div>

                        <div class="modal-activete-cashback__step">
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="56" rx="10" fill="#E3FFB6"/>
                                <path d="M22 25V34C22 35.6569 23.3431 37 25 37H31C32.6569 37 34 35.6569 34 34V25M20 22H36M32 22V21C32 19.8954 31.1046 19 30 19H27.5H26C24.8954 19 24 19.8954 24 21V22M30 28L28 30M28 30L26 32M28 30L26 28M28 30L30 32" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>

                            <span>
                                Корзину наполняйте после перехода
                            </span>
                        </div>

                        <div class="modal-activete-cashback__step">
                            <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="56" rx="10" fill="#E3FFB6"/>
                                <path d="M37.75 26.5C37.9489 26.5 38.1397 26.421 38.2803 26.2803C38.421 26.1397 38.5 25.9489 38.5 25.75V22C38.5 21.6022 38.342 21.2206 38.0607 20.9393C37.7794 20.658 37.3978 20.5 37 20.5H19C18.6022 20.5 18.2206 20.658 17.9393 20.9393C17.658 21.2206 17.5 21.6022 17.5 22V25.75C17.5 25.9489 17.579 26.1397 17.7197 26.2803C17.8603 26.421 18.0511 26.5 18.25 26.5C18.6478 26.5 19.0294 26.658 19.3107 26.9393C19.592 27.2206 19.75 27.6022 19.75 28C19.75 28.3978 19.592 28.7794 19.3107 29.0607C19.0294 29.342 18.6478 29.5 18.25 29.5C18.0511 29.5 17.8603 29.579 17.7197 29.7197C17.579 29.8603 17.5 30.0511 17.5 30.25V34C17.5 34.3978 17.658 34.7794 17.9393 35.0607C18.2206 35.342 18.6022 35.5 19 35.5H37C37.3978 35.5 37.7794 35.342 38.0607 35.0607C38.342 34.7794 38.5 34.3978 38.5 34V30.25C38.5 30.0511 38.421 29.8603 38.2803 29.7197C38.1397 29.579 37.9489 29.5 37.75 29.5C37.3522 29.5 36.9706 29.342 36.6893 29.0607C36.408 28.7794 36.25 28.3978 36.25 28C36.25 27.6022 36.408 27.2206 36.6893 26.9393C36.9706 26.658 37.3522 26.5 37.75 26.5ZM37 30.9025V34H31.75V31.75H30.25V34H19V30.9025C19.642 30.7347 20.2102 30.3588 20.6158 29.8336C21.0214 29.3084 21.2414 28.6636 21.2414 28C21.2414 27.3364 21.0214 26.6916 20.6158 26.1664C20.2102 25.6412 19.642 25.2653 19 25.0975V22H30.25V24.25H31.75V22H37V25.0975C36.358 25.2653 35.7898 25.6412 35.3842 26.1664C34.9786 26.6916 34.7586 27.3364 34.7586 28C34.7586 28.6636 34.9786 29.3084 35.3842 29.8336C35.7898 30.3588 36.358 30.7347 37 30.9025Z" fill="black"/>
                                <path d="M30.25 25.75H31.75V30.25H30.25V25.75Z" fill="black"/>
                            </svg>

                            <span>
                                Не используйте сторонние промокоды
                            </span>
                        </div>

                    </div>

                    <div class="modal-activete-cashback__final">
                        <span class="modal-activete-cashback__title">
                            Кэшбэк в ${title},<br> активирован
                        </span>

                        <svg width="103" height="102" viewBox="0 0 103 102" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="51.5" cy="51" r="46.5" stroke="#F2F2F2" stroke-width="3"/>
                            <path d="M51.5 99C57.8034 99 64.0452 97.7584 69.8688 95.3462C75.6924 92.934 80.9839 89.3983 85.4411 84.9411C89.8983 80.4839 93.434 75.1924 95.8462 69.3688C98.2584 63.5452 99.5 57.3035 99.5 51C99.5 44.6966 98.2584 38.4548 95.8462 32.6312C93.434 26.8076 89.8983 21.5161 85.4411 17.0589C80.9839 12.6017 75.6924 9.06601 69.8688 6.65379C64.0452 4.24156 57.8035 3 51.5 3C45.1966 3 38.9548 4.24155 33.1312 6.65378C27.3076 9.066 22.0161 12.6017 17.5589 17.0589C13.1017 21.5161 9.56601 26.8076 7.15378 32.6312C4.74156 38.4548 3.5 44.6965 3.5 51C3.5 57.3034 4.74156 63.5452 7.15378 69.3688C9.56601 75.1924 13.1017 80.4839 17.5589 84.9411C22.0161 89.3983 27.3076 92.934 33.1312 95.3462C38.9548 97.7584 45.1965 99 51.5 99L51.5 99Z" stroke="black" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M34.625 51L46.4375 64.5L71.75 37.5" stroke="black" stroke-width="5.0625" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    `
    $('body').append(html)
    await new Promise((r, j) => setTimeout(() => r(true), 100))
    $('.modal-activete-cashback').addClass('-active')
}

window.modalActivateCashBackHide = async function () {
    $('.modal-activete-cashback').remove()
}
document.querySelectorAll('[data-sublist-handler]').forEach(item => {
	item.addEventListener('click', () => {
		const sublist = item.parentNode.querySelector(`[data-sublist="${item.getAttribute('data-sublist-handler')}"]`);

		if (sublist) {
			if ($(sublist).is(':visible')) {
				item.classList.remove('-show');
			} else {
				item.classList.add('-show');
			}
		}
	});
});

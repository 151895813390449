$(function () {
	if ($('.shop-category-slider').length) {
		new Swiper('.shop-category-slider .swiper-catalog', {
			watchSlidesProgress: true,
			loop: false,
			// autoplay: {
			//     delay: 8000,
			//     disableOnInteraction: true,
			//     pauseOnMouseEnter: false
			// },
			navigation: {
				nextEl: '.shop-category-slider .swiper-button-next',
				prevEl: '.shop-category-slider .swiper-button-prev',
			},
			spaceBetween: 16,
			slidesPerView: 'auto',
			breakpoints: {
				350: {
					slidesPerView: 'auto',
					spaceBetween: 16,
					slidesPerGroup: 2,
				},
				768: {
					slidesPerView: 6,
					spaceBetween: 20,
				},
				1024: {
					slidesPerView: 7,
					spaceBetween: 20,
					slidesPerGroup: 7,
				},
			},
		});
	}
});

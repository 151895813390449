import IMask from 'imask/esm/imask';
import 'imask/esm/masked/date';

document.querySelectorAll('.dateMask').forEach(item => {
	const mask = IMask(item, {
		mask: Date,
		min: new Date(1900, 0, 1),
		// max: new Date(),
		pattern: 'd.`m.`Y',
		// blocks: {
		// 	d: { mask: IMask.MaskedRange, placeholderChar: 'д', from: 1, to: 31, maxLength: 2 },
		// 	m: { mask: IMask.MaskedRange, placeholderChar: 'м', from: 1, to: 12, maxLength: 2 },
		// 	Y: { mask: IMask.MaskedRange, placeholderChar: 'г', from: 1900, to: 2999, maxLength: 4 },
		// },
		format: date => {
			let _day = date.getDate();
			let _month = date.getMonth() + 1;
			let _year = date.getFullYear();

			if (_day < 10) _day = '0' + _day;
			if (_month < 10) _month = '0' + _month;

			return [_day, _month, _year].join('.');
		},
		lazy: true,
		autofix: true,
		overwrite: true,
	});

	mask.on('accept', () => item.setAttribute('aria-invalid', true));
	mask.on('complete', () => item.setAttribute('aria-invalid', false));

	item.addEventListener('blur', () => {
		mask.updateOptions({ lazy: true });
	});

	item.addEventListener('focus', () => {
		mask.updateOptions({ lazy: false });
	});
});

document.querySelectorAll('.dateMaskClear').forEach(item => {
	const input = item.querySelector('.input');

	if (input) {
		const mask = IMask(input, {
			mask: Date,
			min: new Date(1900, 0, 1),
			// max: new Date(),
			pattern: 'd.`m.`Y',
			// blocks: {
			// 	d: { mask: IMask.MaskedRange, placeholderChar: 'д', from: 1, to: 31, maxLength: 2 },
			// 	m: { mask: IMask.MaskedRange, placeholderChar: 'м', from: 1, to: 12, maxLength: 2 },
			// 	Y: { mask: IMask.MaskedRange, placeholderChar: 'г', from: 1900, to: 2999, maxLength: 4 },
			// },
			format: date => {
				let _day = date.getDate();
				let _month = date.getMonth() + 1;
				let _year = date.getFullYear();

				if (_day < 10) _day = '0' + _day;
				if (_month < 10) _month = '0' + _month;

				return [_day, _month, _year].join('.');
			},
			lazy: true,
			autofix: true,
			overwrite: true,
		});

		mask.on('accept', () => input.setAttribute('aria-invalid', true));
		mask.on('complete', () => input.setAttribute('aria-invalid', false));

		input.addEventListener('blur', () => {
			mask.updateOptions({ lazy: true });
		});

		input.addEventListener('focus', () => {
			mask.updateOptions({ lazy: false });
		});

		const clearBtn = item.querySelector('.clear');

		if (clearBtn) {
			clearBtn.addEventListener('click', () => {
				input.value = '';
				mask.masked.reset();
			});
		}
	}
});

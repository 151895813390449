const blocks = document.querySelectorAll('.block-box');
let currentActiveIndex = 0; // Текущий активный индекс
let timer = null; // Таймер для переключения

let animation = undefined;

// функция для активации блока
function activateBlock(index) {
    if (index >= blocks.length) {
        index = 0; // Начинаем сначала, если достигли конца списка
    }

    $( ".block-box--active .line" ).stop()

    // Удаляем активный класс у всех блоков
    blocks.forEach(block => block.classList.remove('block-box--active'));
    // Добавляем активный класс к текущему блоку
    blocks[index].classList.add('block-box--active');
    // Обновляем текущий активный индекс
    currentActiveIndex = index;


    $( ".block-box--active .line" ).animate({
        width: "100%"
    }, 12000, () =>{
        $( ".block-box--active .line" ).css("width", '0%')
        activateBlock(currentActiveIndex + 1);
    });
}

// Функция, которая будет вызвана, когда элемент войдет в область видимости
function handleIntersection(entries, observer) {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            $( ".block-box--active .line" ).css("width", '0%')
            activateBlock(0);
        }
    });
}

// Создаем экземпляр наблюдателя с указанной функцией обратного вызова
const observer = new IntersectionObserver(handleIntersection);

// Находим элемент с классом animate-block__container и начинаем за ним наблюдение
const target = document.querySelector('.animate-block__container');
if (target) {
    observer.observe(target);
}

// функционал проклика блока
const clickBlocks = document.querySelectorAll('.block-box');

if (clickBlocks) {
    clickBlocks.forEach(block => {
        block.addEventListener('click', function() {
           // if (animation) {
           //     animation.stop();
               $( ".block-box--active .line" ).css("width", '0%')
           // }
            activateBlock(parseInt(this.id));
        });
    });
}
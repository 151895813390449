// функционал открытия блока описания на странице магазина

document.addEventListener('DOMContentLoaded', function() {
    let block = document.querySelector('.notice-block');

    if (block && block.textContent.length <= 450) {
        block.classList.add('expanded');
    }

    if (block) {
        block.addEventListener('click', function() {
            if (!this.classList.contains('expanded')) {
                this.classList.add('expanded');
            }
        });
    }
});
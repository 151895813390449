class FaqItem {
	headClassName = 'head';
	contentClassName = 'content';

	constructor(root) {
		this.root = root;
		this.head = root.querySelector(`.${this.headClassName}`);
		this.content = root.querySelector(`.${this.contentClassName}`);

		this.registerEvents();
	}

	registerEvents() {
		this.head.addEventListener('click', this.toggle.bind(this));
	}

	toggle() {
		this.head.classList.toggle('-active');
		$(this.content).slideToggle();
	}
}

document.querySelectorAll('.faqItem').forEach(root => new FaqItem(root));

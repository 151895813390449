import { hideModal, showModal } from "../utils/modals.js";
export function removeActiveIconClass() {
    let elements = document.getElementsByClassName("-active-webview-icon");

    if (elements) {
        for (let i = 0; i < elements.length; i++) {
            elements[i].classList.remove("-active-webview-icon");
        }
    }
}

export function focusModalInput() {
    let mobileMenu = document.querySelector(".webview-menu-search");
    let searchBlock = mobileMenu.querySelector(".searchWithHints");
    let input = searchBlock.querySelector(".input");
    input.focus();
}

export function webviewMenu() {

    // удаление классов активности при клике на bg
    const bgLayouts = document.querySelectorAll('.bg')
    bgLayouts.forEach(block => {
        block.addEventListener('click', removeActiveIconClass);
    });

    // удаление классов активности на нижней панели при клике на крестик
    const crossBlocks = document.querySelectorAll('.cross');
    crossBlocks.forEach(block => {
        block.addEventListener('click', removeActiveIconClass);
    });

    // Модальное окно после уставноки приложения
    if (!localStorage.getItem('isFirstUX')) {
        const modal = $('.webview-info-modal')

        if (modal.length) {
            setTimeout(() => { showModal(modal) }, 1000)

            modal.find('[data-modal-redirect]').on('click', function () {
                localStorage.setItem('isFirstUX', 'Y')
            })

            modal.find('.cross,.bg,[data-close-modal]').on('click', function () {
                hideModal(modal)
                localStorage.setItem('isFirstUX', 'Y')
            })
        }
    }

    const trigger = document.getElementById('webview-trigger');
    const menu = document.querySelector('.webview-menu-mobile');
    const nextStepTrigger = document.getElementById('next-step-trigger');
    const nextStep = document.querySelector('.next-step');
    const nextStepClose = document.querySelector('.next-step__close');
    let background = document.querySelector('.background')
    let counterDayBlock = document.querySelector('.counter-day')
    const shopSearchInputs = document.querySelectorAll('.shopSearchInput');

    const webviewMenuSearch = document.querySelector('.webview-menu-search');

    // Добавляем обработчик события клика на каждый блок
    if (shopSearchInputs) {
        shopSearchInputs.forEach(shopSearchInput => {
            shopSearchInput.addEventListener('click', () => {
                // Получаем блок с классом .webview-menu-search

                // Проверяем значение свойства display у блока
                if (window.getComputedStyle(webviewMenuSearch).display === 'none') {
                    // 555
                    const searchToggle = document.getElementById('nav-search-trigger')
                    removeActiveIconClass()
                    searchToggle.classList.add("-active-webview-icon");

                    // Задаем значение flex для свойства display
                    webviewMenuSearch.style.display = 'flex';
                    focusModalInput()
                }
            });
        });
    }

    // ловим триггеры для открытия/закрытия модалок с регистрацией
    const regTriggers = document.querySelectorAll('.trigger-for-search')

    if (regTriggers) {
        regTriggers.forEach((trigger) => {
            trigger.addEventListener('click', (e) => {
                // Проверяем значение атрибута data-modal-handler
                if (trigger.getAttribute('data-modal-handler') === 'registerLoginModal#1') {
                    removeActiveIconClass()
                    trigger.classList.add("-active-webview-icon");
                    const modalHandlerValue = trigger.getAttribute('data-modal-handler');
                    setTimeout(() => {
                        trigger.removeAttribute('data-modal-handler');
                        trigger.setAttribute('clicked-data-modal-handler', modalHandlerValue);
                    }, 300)
                } else {
                    trigger.classList.remove("-active-webview-icon");
                    const test = $('[data-modal-js="registerLoginModal"]')
                    if (test) {
                        e.stopPropagation();
                        e.preventDefault();
                        hideModal(test.eq(0))
                    }
                }
            });
        })
    }

    if (counterDayBlock) {
        if (background) {
            const heightUpBlock = parseFloat(getComputedStyle($('.counter-day')[0]).height) + parseFloat(getComputedStyle($('.counter-day')[0]).paddingTop)
            $('.webview-nav').attr('style', `--counter-height:${heightUpBlock}px`)
        } else {
            const heightUpBlock = parseFloat(getComputedStyle($('.counter-day')[0]).height)
            $('.webview-nav').attr('style', `--counter-height:${heightUpBlock}px`)
        }
    }

    const triggersForSearch = document.querySelectorAll('.trigger-for-search')

    let isNextStepVisible = false;

    // переменные для меню поиска
    var searchTrigger = document.getElementById("nav-search-trigger");
    var menuSearch = document.querySelector(".webview-menu-search");

    if (trigger) {
        trigger.addEventListener('click', () => {
            // скрытие меню с поиском
            menuSearch.style.display = "none";

            if (menu.style.display === "flex") {
                menu.style.display = 'none';
                document.body.style.overflow = 'auto';
                trigger.classList.remove("-active-webview-icon");
            } else {
                menu.style.display = 'flex';
                document.body.style.overflow = 'hidden';
                removeActiveIconClass()
                trigger.classList.add("-active-webview-icon");
            }
        });
    }

    if (nextStep) {
        nextStepTrigger.addEventListener('click', () => {
            nextStep.style.display = 'flex';
            document.body.style.overflow = 'hidden';
            isNextStepVisible = true;
        });
    }

    if (nextStep) {
        nextStepClose.addEventListener('click', () => {
            nextStep.style.display = 'none';
            document.body.style.overflow = 'hidden';
            isNextStepVisible = false;
        });
    }



    if (searchTrigger) {
        // функционал открытия меню поиска
        searchTrigger.addEventListener("click", function () {
            let modal = document.querySelector('.registerLoginModal')

            if (background) {
                if (modal) {
                    modal.classList.remove("-show");
                }

                // функционал на неавторизованной странице
                if (menuSearch.style.display === "flex") {
                    menuSearch.style.display = "none";
                    document.body.style.overflow = 'auto';
                    background.style.background = '#E3FFB6';
                    searchTrigger.classList.remove("-active-webview-icon");
                } else {
                    background.style.background = 'transparent';
                    menuSearch.style.display = "flex";
                    document.body.style.overflow = 'hidden';
                    removeActiveIconClass()
                    searchTrigger.classList.add("-active-webview-icon");
                    focusModalInput()
                }
            } else {
                // функционал на авторизованной странице
                if (modal) {
                    modal.classList.remove("-show");
                }

                // скрытие меню пользователя
                if (menu) {
                    menu.style.display = 'none';
                }

                if (menuSearch.style.display === "flex") {
                    menuSearch.style.display = "none";
                    document.body.style.overflow = 'auto';
                    searchTrigger.classList.remove("-active-webview-icon");
                } else {
                    menuSearch.style.display = "flex";
                    let mobileMenu = document.querySelector(".webview-menu-search");
                    let searchBlock = mobileMenu.querySelector(".searchWithHints");
                    let input = searchBlock.querySelector(".input");
                    document.body.style.overflow = 'hidden';
                    removeActiveIconClass()
                    searchTrigger.classList.add("-active-webview-icon");
                    input.focus();
                }
            }
        });
    }

    if (triggersForSearch) {
        triggersForSearch.forEach(element => {
            element.addEventListener("click", function () {
                if (background) {
                    background.style.background = '#E3FFB6';
                }

                menuSearch.style.display = "none";
                document.body.style.overflow = 'auto';
            })
        })
    }

    // очиска инпута поиска в модалке при нажатии на кнопку - 'Отмена'
    const searchContainer = document.querySelector('.search-container__cancel')
    if (searchContainer) {
        searchContainer.addEventListener('click', function () {
            if (background) {
                background.style.background = '#E3FFB6';
            }
            removeActiveIconClass();
            let input = this.parentElement.querySelector('.input');
            input.value = '';
            webviewMenuSearch.style.display = 'none';
        });
    }

    // открытие / закрытие модального окна "выход из аккаунта"
    const toggleExit = document.getElementById('toggleExitModal')
    if (toggleExit) {
        toggleExit.addEventListener('click', function () {
            document.querySelector('.exit-modal').classList.add("exit-modal--active");
        });
    }
    const closeExitModal = document.getElementById('closeExitModal')
    if (closeExitModal) {
        closeExitModal.addEventListener('click', function () {
            document.querySelector('.exit-modal').classList.remove("exit-modal--active");
        });
    }
    document.addEventListener('click', function (event) {
        const modal = document.querySelector('.exit-modal--active');

        if (modal && !modal.contains(event.target) && !toggleExit.contains(event.target)) {
            modal.classList.remove('exit-modal--active');
        }
    });

    // функционал заполнения "любимых магазинов" на стартовом этапе модалки поиска
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        if (xhr.status === 200) {
            let response = JSON.parse(xhr.responseText).popularShops;
            let favoriteShopsList = document.querySelector('.favoriveShopsList');

            if (favoriteShopsList) {
                favoriteShopsList.innerHTML = '';


                let paragraph = document.createElement("p");
                paragraph.classList.add("shop-title");
                paragraph.textContent = "Популярные магазины";
                favoriteShopsList.appendChild(paragraph);

                let ulElement = document.createElement('ul');
                response.forEach((shop) => {
                    let aElement = document.createElement('a');
                    aElement.textContent = shop.shopName;
                    aElement.setAttribute('href', `/webview/shop-list/${shop.shopTechId}`);
                    ulElement.appendChild(aElement);
                });

                favoriteShopsList.appendChild(ulElement);
            }
        }
    };

    xhr.open('GET', '/shops/list/most/popular/ajax', true);
    xhr.send();
}
$(function () {
    const $root = $('.cashback-tabs')

    if ($root.length) {
        $root.find('button').on('click', function () {
            $root.find('button[data-tab-active]').removeAttr('data-tab-active')
            $root.find('.cashback-tabs__tab[data-tab-active]').removeAttr('data-tab-active')

            $(this).attr('data-tab-active', '1')

            const index = $(this).attr('data-tab-btn')
            $root.find(`.cashback-tabs__tab[data-tab-index="${index}"]`).attr('data-tab-active', '1')

            let left = ($(this).offset().left - $root.find('.cashback-tabs__header').offset().left)

            if ($(document).width() < 768) {
                left -= 16
            }

            $root.find('.cashback-tabs__header').animate({
                scrollLeft: `+=${left}px`
            }, "slow");
        })
    }
})
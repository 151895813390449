export function imageBannerSliderRow() {
    new Swiper('.image-banner-slider-row .image-banner-slider-row-wrap:not(.swiper-initialized)', {
        loop: false,
        autoplay: {
            delay: 8000,
            disableOnInteraction: true,
            pauseOnMouseEnter: false
        },
        navigation: {
            nextEl: '.image-banner-slider-row .swiper-button-next',
            prevEl: '.image-banner-slider-row .swiper-button-prev',
        },
        pagination: {
            el: ".image-banner-slider-row .swiper-pagination",
        },
        slidesPerView: 'auto',
        spaceBetween: 0,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 20,
            },

            1024: {
                slidesPerView: 3,
                spaceBetween: 20,
            }
        }
    })
}
import { disableNoScrollBody, noScrollBody } from './main';

export function hideModal($modal) {

	$modal.removeClass('-show');
	$modal.trigger('hide');

	disableNoScrollBody();

	const blocks = document.querySelectorAll('[clicked-data-modal-handler]');

	// Проходимся по каждому блоку
	if (blocks.length) {
		blocks.forEach(block => {
			// Удаляем свойство clicked-data-modal-handler
			const modalHandlerValue = block.getAttribute('clicked-data-modal-handler');
			block.removeAttribute('clicked-data-modal-handler');
			block.setAttribute('data-modal-handler', modalHandlerValue);
		});
	}

	document.body.style.overflow = 'auto';
}

export function showModal($modal, timeout = false) {
	hideAllModal();



	$modal.addClass('-show');
	$modal.trigger('show');

	requestAnimationFrame(() => noScrollBody());

	if (timeout) {
		setTimeout(() => hideModal($modal), timeout);
	}

	document.body.style.overflow = 'hidden';
}

export function hideAllModal() {
	document.querySelectorAll('[data-modal-js]').forEach(modal => hideModal($(modal)));
}

export function showSomethingWrongModal(text = 'Похоже что-то пошло не так') {
	const $modal = $('[data-modal-js="somethingWrongModal"]');

	if (!$modal.length) return;

	$modal.find('.content > .title').html(text);
	showModal($modal);
}

export function showSuccessModal(title = '', subtitle = '', timeout = false) {
	title = title === null ? 'Действие прошло успешно' : title;

	const $modal = $('[data-modal-js="successModal"]');
	if (!$modal.length) return;

	$modal.find('.content > .title').html(title);
	$modal.find('.content > .subtitle').html(subtitle);

	showModal($modal, timeout);
}

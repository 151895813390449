import IMask from 'imask';

document.querySelectorAll('.moneyMask').forEach(item => {
	IMask(item, {
		mask: Number,
		scale: 2,
        radix: '.',
		min: 0,
		max: 9999999.99
	});
});
const $stickyHeader = document.querySelector('.stickyHeader');

if ($stickyHeader) {
	document.addEventListener('scroll', () => {
		if (window.pageYOffset > 16) {
			$stickyHeader.classList.add('-fixed');
		} else {
			$stickyHeader.classList.remove('-fixed');
		}
	});
}

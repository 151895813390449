$('.tabs').not('[data-tabs-active-js]').each(function() {
	let ths = $(this);

	ths.find('.tabs__item').hide().eq(0).show();

	ths.find('.tabs__header button').click(function() {
		if ($(this).hasClass('active')) return;

		ths
			.find('.tabs__header button')
			.removeClass('active')
			.eq($(this).index())
			.addClass('active');

		ths.find('.tabs__item').hide().eq($(this).index()).fadeIn(800);
	})
		.eq(0)
		.addClass('active');
});

document.querySelectorAll('[data-tabs-active-js]').forEach(root => {
	const $root = $(root);
	const $buttons = $root.find('.tabs__header button');
	const $items = $root.find('.tabs__item');
	const initIndex = parseInt($root.attr('data-tabs-active-js'));

	$buttons.removeClass('active').eq(initIndex).addClass('active');
	$items.hide().eq(initIndex).show();

	$buttons.each((index, button) => {
		button.addEventListener('click', () => {
			if (parseInt($root.attr('data-tabs-active-js')) === index) return;

			$root.trigger('indexChange', index);
		});
	});

	$root.on('indexChange', (e, index) => {
		$root.attr('data-tabs-active-js', index);
		$buttons.removeClass('active').eq(index).addClass('active');
		$items.hide().eq(index).fadeIn(800);
	});
});

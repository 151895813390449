import { showModal, showSomethingWrongModal, showSuccessModal } from '../../utils/modals';
import { disabledForm, enabledForm, FormValidator } from '../../utils/form';


(() => {
	let timeout;
	const $registerLoginModal = $('.registerLoginModal');

	$registerLoginModal.each((index, modal) => {
		const $modal = $(modal);

		$modal.on('show', (e) => {
			if (timeout) clearTimeout(timeout);

			$(e.currentTarget).find('.tabs__header').removeClass('-disabled');
			// $(e.currentTarget).find('.registerModalForm').prop('disabled', false).val('');
			$(e.currentTarget).find('.registerModalForm #registration_email').prop('disabled', false).val('');
			$(e.currentTarget).find('.registerModalForm #registrationPassword').prop('disabled', false).val('');
			$(e.currentTarget).find('.registerModalForm #hashRegPassword').prop('disabled', false).val('');

			$(e.currentTarget).find('.button').prop('disabled', false);
			$(e.currentTarget).find('.tabs').show();
			$(e.currentTarget).find('.registerDone').addClass('-hide');

			// if ($('form .h-captcha')) {
			// 	$('form .h-captcha').parents('form').find('[type="submit"]').attr('disabled', 'true')
			// }
		});

		$modal.on('hide', (e) => {
			const captchaOpenModal = document.querySelector('.--captcha-active-mode')
			if (captchaOpenModal) {
				captchaOpenModal.classList.remove('--captcha-active-mode')

				$('.registration-button').eq(0).click()

				return false;
			}
		});

		// if ($('form .h-captcha')) {
		// 	$('form .h-captcha').parents('form').find('[type="submit"]').attr('disabled', 'true')
		// }

		const $registerModalForm = modal.querySelector('.registerModalForm');

		window.accessFormNext = false

		window.hcaptchaModalMyEvents = (e) => {
			window.accessFormNext = true;

			const captchaOpenModal = document.querySelector('.--captcha-active-mode')
			if (captchaOpenModal) {
				captchaOpenModal.classList.remove('--captcha-active-mode')
			}

			setTimeout(()=>{
				$(".modal #registration").click()
			},300)
		}


		// wip reg
		if ($registerModalForm) {
			const registerModalFormValidator = new FormValidator($registerModalForm);

			registerModalFormValidator.addField('[name="email"]', [FormValidator.rules.required, FormValidator.rules.email, FormValidator.rules.maxLength(200, 'Максимум 200 символов')]);
			registerModalFormValidator.addField('[name="password"]', [FormValidator.rules.required, FormValidator.rules.password]);
			registerModalFormValidator.addField('[name="isAgree"]', [FormValidator.rules.isChecked('Для регистрации вы должны согласиться с условиями Пользовательского соглашения и обработкой персональных данных')]);

			registerModalFormValidator.onSuccess((form) => {
				if(window.ym) {
					ym(89406843, "reachGoal", "click_button_registration_form");
				}
				const modalWindow = document.querySelector('.register-login-modal-tabs')
				const captcha = document.querySelector('.desktopCaptcha');

				if (captcha && !window.accessFormNext) {
					modalWindow.classList.add('--captcha-active-mode')
					return false;
				}



				disabledForm(form);
				$modal.find('.tabs__header').addClass('-disabled');

				const actionUrl = form.getAttribute('action');
				const mailUserInput = $('#registration_email');
				const passwordUserInput = $('#registrationPassword');
				const routeNameInput = $('#routeName');
				const captchaKey = $('.h-captcha').length ? $registerModalForm.querySelector('.h-captcha iframe').getAttribute('data-hcaptcha-response') : null;

				const jsonData = JSON.stringify({
					'captchaKey': captchaKey,
					'email': mailUserInput.val(),
					'password': passwordUserInput.val(),
					'newsletter': form.querySelector('[name="newsletter"]').checked,
					'isAgree': form.querySelector('[name="isAgree"]').checked,
					'routeName': form.querySelector('[name="routeName"]').value,
					'redirectTo': form.querySelector('[name="redirectTo"]').value
				});

				$.ajax({
					url: actionUrl,
					method: 'POST',
					data: jsonData,
					success: function (response) {
						if (response.status && response.status === 200) {
							if (response.data.isResendEmail) {
								showSuccessModal('Письмо, с ссылкой для подтверждения, отправлено вам на почту');
							} else {

								if (window.location.href.includes('cashback/registration') || window.location.href.includes('cashback/authorization')) {
									let element = document.querySelector('.register-login-modal-tabs');
									let greatElement = document.querySelector('.maked-register-tabs')
									if (element) {
										element.style.display = 'none';
									}
									if (greatElement) {
										greatElement.style.display = 'flex';
									}
								} else {
									showSuccessModal('Регистрация прошла успешно!', '', 3000);
								}


								const encrypt = new JSEncrypt();
								encrypt.setPublicKey($('#regPublicKey').val());
								const encrypted = encrypt.encrypt($('#registrationPassword').val());
								const encodePasswordInput = $('#hashRegPassword');
								// const mailUserInput = mailUserInput;

								encodePasswordInput.val(encrypted);

								const jsonData = JSON.stringify({
									'email': mailUserInput.val(),
									'password': encodePasswordInput.val(),
									'routeName': routeNameInput.val()
								});

								const request = $.ajax({
									url: '/api/authentication_token',
									type: 'POST',
									data: jsonData,
									contentType: 'application/json',
									dataType: 'json',
								});

								request.done(function (msg) {
									localStorage.setItem('authorize_token', msg.token);
									localStorage.removeItem('firebasePlayerID')

									let formData = new FormData();
									formData.append('email', JSON.parse(jsonData).email);
									formData.append('password', JSON.parse(jsonData).password);
									formData.append('_csrf_token', $('#csrfReg').val());

									if ($modal.attr('data-modal-redirect')) {
										formData.append('redirectUrl', $modal.attr('data-modal-redirect'))
									}

									$.ajax({
										url: '/sign',
										method: 'POST',
										data: formData,
										processData: false,
										contentType: false,
									}).done(function () {
										window.location.replace(response.data.redirectTo);
									});
								});

								request.fail(function (msg) {
									const openedModal = document.querySelector('.registerLoginModal.-show');
									const $somethingWrongModal = $('[data-modal-js="somethingWrongModal"]');

									switch (msg.responseJSON.code) {
										case 401:
											showSomethingWrongModal('Неверный логин или пароль');
											break;
										case 404:
											showSomethingWrongModal('Неверный логин или пароль');
											break;
										case 423:
											showSomethingWrongModal('Данный аккаунт заблокирован. Свяжитесь с администрацией');
											break;
										default:
											showSomethingWrongModal('Неизвестная ошибка');
									}

									if (openedModal) {
										$somethingWrongModal.one('hide', () => {
											showModal($(openedModal));
										});
									}

									$modal.find('.tabs__header').removeClass('-disabled');
									enabledForm(form);
								});
							}
						} else if (response.status && response.status === 'error') {
							showSomethingWrongModal(response.data.error_msg);
						} else if (response.status && response.status === 429) {
							showSomethingWrongModal(response.data.error_msg);
						}
					},
					complete: function () {
						enabledForm(form);
						$modal.find('.tabs__header').removeClass('-disabled');
					},
				});
			});
		}

		const $loginModalForm = modal.querySelector('.loginModalForm');

		if ($loginModalForm) {
			const loginModalFormValidator = new FormValidator($loginModalForm);
			// wip auth
			loginModalFormValidator.addField('[name="email"]', [FormValidator.rules.required, FormValidator.rules.email]);
			loginModalFormValidator.addField('[name="password"]', [FormValidator.rules.required]);

			loginModalFormValidator.onSuccess(form => {
				disabledForm(form);
				$modal.find('.tabs__header').addClass('-disabled');

				const encrypt = new JSEncrypt();
				encrypt.setPublicKey($(form).find('#publicKey').val());
				const encrypted = encrypt.encrypt($(form).find('#userSitePassword').val());
				const encodePasswordInput = $(form).find('#hashPassword');
				const mailUserInput = $(form).find('#userSiteEmail');
				const routeNameInput = $(form).find('#routeName');

				encodePasswordInput.val(encrypted);

				const jsonData = JSON.stringify({
					'email': mailUserInput.val(),
					'password': encodePasswordInput.val(),
					'routeName': routeNameInput.val()
				});

				if ($modal.attr('data-modal-redirect')) {
					form = $(form).append(`<input name="redirectUrl" value="${$modal.attr('data-modal-redirect')}" />`)[0]
				}

				const request = $.ajax({
					url: '/api/authentication_token',
					type: 'POST',
					data: jsonData,
					contentType: 'application/json',
					dataType: 'json',
				});

				request.done(function (msg) {
					localStorage.setItem('authorize_token', msg.token);
					localStorage.removeItem('firebasePlayerID')
					form.submit();
				});

				request.fail(function (msg) {
					const openedModal = document.querySelector('.registerLoginModal.-show');
					const $somethingWrongModal = $('[data-modal-js="somethingWrongModal"]');

					switch (msg.responseJSON.code) {
						case 401:
							showSomethingWrongModal('Неверный логин или пароль');
							break;
						case 404:
							showSomethingWrongModal('Неверный логин или пароль');
							break;
						case 423:
							showSomethingWrongModal('Данный аккаунт заблокирован. Свяжитесь с администрацией');
							break;
						default:
							showSomethingWrongModal('Неизвестная ошибка');
					}

					if (openedModal) {
						$somethingWrongModal.one('hide', () => {
							showModal($(openedModal));
						});
					}

					$modal.find('.tabs__header').removeClass('-disabled');
					enabledForm(form);
				});
			});
		}
	});
})();

$(function () {
    if (!$('.search-geo-city').length) return;

    let fullItems = [];
    let activeItem = location.href

    const render = (items) => {
        let $listEl = $('.search-geo-city__list')
        let $btnShow = $('#showOfferGeo')

        $listEl.html('')

        items.map(({ key, val }) => {
            const span = $('<span></span>')
            span.text(key)
            span.attr('data-url', val)

            if (val == activeItem) {
                span.addClass('geo-active')
            }

            span.on('click', function () {
                const href = $(this).attr('data-url')
                activeItem = href

                $listEl.find('.geo-active').removeClass('geo-active')
                $(this).addClass('geo-active')

                if (!$listEl.find('.geo-active').length) {
                    $btnShow.attr('disabled', 'disabled')
                } else {
                    $btnShow.removeAttr('disabled')
                }
            })

            $listEl.append(span)
        })

        if (!items.length) {
            const nf = $('<div>Город не найден</div>')
            $listEl.append(nf)
        }

        if (!$listEl.find('.geo-active').length) {
            $btnShow.attr('disabled', 'disabled')
        } else {
            $btnShow.removeAttr('disabled')
        }
    }

    $.ajax({
        url: '/ajax/city/list',
        dataType: "json",
        contentType: "application/json; charset=utf-8",
        method: "POST",
        data: JSON.stringify({ "finsysRoute": location.href })
    }
    ).then(res => {
        if (res.data) {
            const keys = Object.keys(res.data)

            for (let key of keys) {
                fullItems.push({ "key": key, 'val': res.data[key] })
            }

            render(fullItems)
        }
    })

    $('.search-geo-city__input input').on('input', function () {
        const val = $(this).val()
        const items = fullItems.filter(({ key }) => {
            return key.toLowerCase().indexOf(val.toLowerCase()) !== -1
        })

        render(items)
    })

    $('#openParametersGeo').on('click', function () {
        $('body').css('overflow', 'hidden');
        $('.add-parameters').show();
    })

    $('#clearInputsGeo').on('click', function () {
        $('.search-geo-city__input input').val('')
        activeItem = ''
        render(fullItems)
    })

    $('#showOfferGeo').on('click', function () {
        location.href = activeItem
    })
})
const lettersToCut = 350;
const contentWrapper = document.querySelectorAll('.reviews-about-partner__inner .partners__page-reviews-item .partners__page-reviews-item-content-text');

contentWrapper.forEach(item => {
    const lengthStr = item.innerText.length;
    if (lengthStr > lettersToCut) {
        const contentTextFirst = item.innerText;
        let contentText = item.innerText;
        contentText = contentText.slice(0, lettersToCut);
        item.innerText = contentText;
        const link = document.createElement('a');
        link.classList.add('showReview');
        link.innerText = ' Читать далее';
        item.append(link);
        link.addEventListener('click', function () {
            if (this.classList.contains('active')) {
                this.innerText = ' Читать далее';
                item.innerText = contentText;
                item.append(link);
            } else {
                this.innerText = ' Скрыть';
                item.innerText = contentTextFirst;
                item.append(link);
            }
            this.classList.toggle('active');
        })
    }
})


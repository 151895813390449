import JustValidate from 'just-validate';

export function disabledForm(form) {
    form.setAttribute('aria-disabled', true);
    form.querySelectorAll('input').forEach(item => item.blur());
}

export function enabledForm(form) {
    form.reset();
    form.removeAttribute('aria-disabled');
}

export class FormValidator {
    config = {
        errorFieldCssClass: '-error',
        focusInvalidField: true,
        lockForm: true,
        errorLabelCssClass: 'error',
    };

    static wrapErrorMessage = (text) => {
        return `
				<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'>
					<g stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.333' clip-path='url(#errorIconCPA)'>
						<path d='M8 14.667A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334ZM10 6l-4 4m0-4 4 4'/>
					</g>
					<defs>
						<clipPath id='errorIconCPA'><path fill='#fff' d='M0 0h16v16H0z'/></clipPath>
					</defs>
				</svg>
				<span class='text'>${text}</span>
			`;
    };

    static rules = {
        required: {
            rule: 'required',
            errorMessage: this.wrapErrorMessage('Это поле является обязательным для заполнения'),
        },
        isChecked: (message = '') => ({
            rule: 'required',
            errorMessage: this.wrapErrorMessage(message)
        }),
        email: {
            rule: 'customRegexp',
            value: /^\S+@\S+\.\S+$/,
            errorMessage: this.wrapErrorMessage('Проверьте правильность заполнения поля'),
        },
        password: {
            rule: 'customRegexp',
            value: /^(?=.*[A-Za-z\W])(?=.*\d)[A-Za-z\d\W]{6,}$/,
            errorMessage: this.wrapErrorMessage('Минимум 6 символов, хотя бы одна цифра и буква'),
        },
        date: {
            rule: 'customRegexp',
            value: /^\d{2}.\d{2}.\d{4}$/,
            errorMessage: this.wrapErrorMessage('Проверьте правильность заполнения поля'),
        },
        minLength: (minLength, message = `Минимальное значение ${minLength}`) => ({
            rule: 'minLength',
            value: minLength,
            errorMessage: this.wrapErrorMessage(message),
        }),
        maxLength: (maxLength, message = `Максимальное значение ${maxLength}`) => ({
            rule: 'maxLength',
            value: maxLength,
            errorMessage: this.wrapErrorMessage(message),
        }),
        passwordCompare: ($field) => ({
            rule: 'compare',
            errorMessage: this.wrapErrorMessage('Пароли должны совпадать'),
            validator: (value) => {
                return value === $field.value;
            },
        }),
        cardNumber: {
            rule: 'customRegexp',
            value: /^\d{4}.\d{4}.\d{4}.\d{4}$/,
            errorMessage: this.wrapErrorMessage('Введите 16 цифр номера карты'),
        },
        files: (value, message = '') => ({
            rule: 'files',
            value: {
                files: value,
            },
            errorMessage: this.wrapErrorMessage(message),
        }),
        maxFiles: (value, message = '') => ({
            rule: 'maxFilesCount',
            value,
            errorMessage: this.wrapErrorMessage(message),
        }),
        copiedURL: {
            rule: 'customRegexp',
            value: /^(http:\/\/)|(https:\/\/)/,
            errorMessage: this.wrapErrorMessage('Ошибка. Скопируйте ссылку целиком, не сокращая'),
        },
        phone: {
            rule: 'customRegexp',
            value: /^(\+)[\d]{1}\ \([\d]{2,3}\)\ [\d]{2,3}-[\d]{2,3}-[\d]{2,3}$/, // +7 (999) 999-99-99
            errorMessage: this.wrapErrorMessage('Некорректный номер телефона'),
        },
    };

    constructor(form) {
        this.form = form;
        this.jvInstance = new JustValidate(form, this.config);

        this.onSuccess(() => {
            disabledForm(form);
            form.submit();
        });
    }

    onSuccess = (callback) => {
        this.jvInstance.onSuccess(() => callback(this.form));
    };

    addField = (field, rules = [], config = {}) => {
        let errorsContainer = this.form.querySelector(field).closest('.form-field') || this.form.querySelector(field).parentElement

        if (errorsContainer.classList.contains('checkbox')) {
            errorsContainer.insertAdjacentHTML('beforebegin', '<div class="form-field error-wrap"></div>')
            errorsContainer = errorsContainer.previousElementSibling
        }

        this.jvInstance.addField(field, rules, {
            errorsContainer,
            ...config,
        });
    };
}
